<template>
  <div class="grid-x grid-margin-x">
    <portal to="pageHeaderRight">
      <submit-button
        :isSaving="isSaving"
        v-if="$store.state.is_godfather"
      ></submit-button>
    </portal>
    <div class="cell medium-6">
      <form @submit.prevent="formSubmit">
        <input type="submit" id="submitForm" hidden=""/>
        <card-item :title="$t('company.details')">
          <ab-input-field
            :label="$t('company.company_name')"
            :readonly="readonly"
            v-model="settings.company_name"
            :error="$v.settings.company_name.$error"
            :errorMessage="$t('forms.no_valid_data')"
          ></ab-input-field>
          <ab-input-field
            :label="$t('company.coc_number')"
            :readonly="readonly"
            v-model="settings.coc_number"
            :error="$v.settings.coc_number.$error"
            :errorMessage="$t('forms.no_valid_data')"
          ></ab-input-field>

          <ab-input-field
            :label="$t('company.company_type')"
            :readonly="readonly"
            v-model="settings.company_type"
            :error="$v.settings.company_type.$error"
            :errorMessage="$t('forms.no_valid_data')"
          ></ab-input-field>

        </card-item>

        <div class="spacer--20"></div>

        <card-item :title="$t('company.payment')">
          <ab-input-field
            :label="$t('company.vat_number')"
            :readonly="readonly"
            v-model="settings.vat_number"
            :error="$v.settings.vat_number.$error"
            :errorMessage="$t('forms.no_valid_data')"
          ></ab-input-field>

          <ab-input-field
            :label="$t('company.billing_address')"
            :readonly="readonly"
            v-model="settings.billing_address"
            :error="$v.settings.billing_address.$error"
            :errorMessage="$t('forms.no_valid_data')"
          ></ab-input-field>

          <ab-input-field
            :label="$t('company.billing_email')"
            :readonly="readonly"
            v-model="settings.billing_email"
            :error="$v.settings.billing_email.$error"
            :errorMessage="$t('forms.valid_email')"
          ></ab-input-field>

          <ab-input-field
            :label="$t('company.iban')"
            :readonly="readonly"
            v-model="settings.iban"
            :error="$v.settings.iban.$error"
            :errorMessage="$t('forms.no_valid_iban')"
          ></ab-input-field>
        </card-item>
      </form>
    </div>
    <div class="cell medium-6">
      <company-subscription/>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'
import { email, minLength, required } from 'vuelidate/lib/validators'
import isValidVatNumber from '@/helpers/validators/isValidVatNumber'
import isValidIban from '@/helpers/validators/isValidIban'
import companySubscription from '@/views/settings/pages/CompanySubscription'

export default {
  name: 'CompanyInfo',
  data () {
    return {
      settings: {},
      readonly: true,
      isSaving: false
    }
  },
  created () {
    backend.get(`${process.env.VUE_APP_URL}/company_details`).then(response => {
      this.settings = response.data
      if (this.$store.state.is_godfather) {
        this.readonly = false
      }
    })
  },
  methods: {
    formSubmit (e) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isSaving = true
        backend
          .post(`${process.env.VUE_APP_URL}/company_details`, this.settings)
          .then(() => {
            this.$notify({
              group: 'custom-template',
              title: false,
              position: 'bottom left',
              text: this.$t('notify.changes_saved')
            })
            this.isSaving = false
          })
          .catch(() => {
            this.isSaving = false
            this.$notify({
              group: 'custom-template-error',
              title: false,
              position: 'bottom left',
              text: this.$t('notify.unable_to_save_changes')
            })
          })
      }
    }
  },
  validations: {
    settings: {
      company_name: {
        required,
        minLength: minLength(1)
      },
      coc_number: {
        required,
        minLength: minLength(1)
      },
      company_type: {
        required,
        minLength: minLength(1)
      },
      vat_number: {
        required,
        minLength: minLength(1),
        isValidVatNumber
      },
      billing_address: {
        required,
        minLength: minLength(1)
      },
      billing_email: {
        required,
        email,
        minLength: minLength(1)
      },
      iban: {
        minLength: minLength(1),
        isValidIban
      }
    }
  },
  components: {
    'company-subscription': companySubscription
  }
}
</script>

<style lang="scss" scoped>
.alert {
  border: 1px solid #cc3e3e;
  color: #cc3e3e;

  i {
    font-size: 16px;
    margin-right: 15px;
  }
}
</style>
