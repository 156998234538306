<template>
  <div class="grid-x grid-margin-x" v-if="details">
    <div class="cell medium-12">

      <card-item :margin="true" :title="$t('company.current_plan')" class="current-subscription">
        <template v-slot:header-right>
          <a
            @click="show('upgradeSubscriptionStorageModal')"
          >
            {{ $t('company.upgrade') }}
          </a>
        </template>
        <div class="spacer--30"></div>
        <div class="grid-x grid-margin-x">
          <div class="cell auto">
            <h2>{{ currentSubscription.name }} </h2>
          </div>
          <div class="cell shrink float-right">
            <p>&euro; {{ currentSubscription.price }} </p>
          </div>
        </div>
      </card-item>

      <card-item :margin="true" :title="$t('company.storage')" icon="icon-company-settings-storage">
        <div class="grid-x grid-margin-x">
          <div class="cell shrink">
            {{ diskUsage }}
          </div>
          <div class="cell auto">
            <progress-bar :percentage="diskUsagePercentage"/>
          </div>
        </div>
      </card-item>

      <card-item
        :margin="true"
        :title="$t('company.users')"
        icon="icon-menu-profile"
      >
        <div class="grid-x grid-margin-x">
          <div class="cell shrink">
            {{ usersUsage }}
          </div>
          <div class="cell auto">
            <progress-bar :percentage="usersPercentage"/>
          </div>
        </div>
      </card-item>

      <card-item :title="$t('company.brands')" icon="icon-menu-brands">
        <div class="grid-x grid-margin-x">
          <div class="cell shrink">
            {{ brandUsage }}
          </div>
          <div class="cell auto">
            <progress-bar :percentage="brandPercentage"/>
          </div>
        </div>
      </card-item>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'
import progressBar from '@/views/common/components/elements/ProgressBar'
import upgradeSubscriptionStorageModal from '@/views/settings/components/modals/upgradeSubscriptionStorageModal'

export default {
  methods: {
    show (index) {
      this.$modal.show(
        upgradeSubscriptionStorageModal,
        {
          handlers: {
            refresh: () => {
              this.refreshCurrentSubscription()
            }
          }
        },
        {
          draggable: false,
          scrollable: false,
          height: 'auto',
          width: '800px'
        }
      )
    },
    refreshCurrentSubscription () {
      backend
        .get(`${process.env.VUE_APP_URL}/company_details/subscription`)
        .then(response => {
          this.details = response.data
          this.currentSubscription = this.details.currentSubscription
          this.currentFeatures = this.currentSubscription.features
        })
    }
  },
  name: 'CompanySubscription',
  data () {
    return {
      details: null,
      currentSubscription: [],
      currentFeatures: [],
      isSaving: false
    }
  },
  created () {
    this.refreshCurrentSubscription()
  },
  computed: {
    diskUsage: function () {
      if (!this.currentFeatures.maximum_disk_usage) {
        return this.$t('company.unlimited')
      }
      const used = parseInt(this.details.disk_usage / 1024)
      const allowed = parseInt(
        this.currentFeatures.maximum_disk_usage / 1024
      )
      return used + '/' + allowed + ' GB'
    },
    diskUsagePercentage: function () {
      if (!this.currentFeatures.maximum_disk_usage) {
        return 0
      }
      return (
        (this.details.disk_usage /
          this.currentFeatures.maximum_disk_usage) *
        100
      )
    },
    usersUsage: function () {
      if (!this.currentFeatures.maximum_user_count) {
        return this.$t('company.unlimited')
      }
      const used = this.details.user_count
      const allowed = this.currentFeatures.maximum_user_count
      return used + '/' + allowed
    },
    usersPercentage: function () {
      if (!this.currentFeatures.maximum_user_count) {
        return 0
      }
      return (
        (this.details.user_count /
          this.currentFeatures.maximum_user_count) *
        100
      )
    },
    brandUsage: function () {
      if (!this.currentFeatures.maximum_brand_count) {
        return this.$t('company.unlimited')
      }
      const used = this.details.brand_count
      const allowed = this.currentFeatures.maximum_brand_count
      return used + '/' + allowed
    },
    brandPercentage: function () {
      if (!this.currentFeatures.maximum_brand_count) {
        return 0
      }
      return (
        (this.details.brand_count /
          this.currentFeatures.maximum_brand_count) *
        100
      )
    }
  },
  components: {
    'progress-bar': progressBar
  }
}
</script>

<style lang="scss" scoped>
.current-subscription {
  background: var(--button-primary-color);
  color: var(--button-primary-text-color);
  border-radius: $global-radius;
}

.alert {
  border: 1px solid #cc3e3e;
  color: #cc3e3e;

  i {
    font-size: 16px;
    margin-right: 15px;
  }
}

.disabled-button {
  color: #d0d0d0;
}
</style>
