<template>
  <div class="progress_bar">
    <div class="progress" :style="percentageStyle"></div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    percentage: Number
  },
  computed: {
    percentageStyle: function () {
      const percentage = parseInt(this.percentage) + '%'
      return { width: percentage }
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/_settings";

.progress_bar {
  display: inline-block;
  width: 100%;
  background-color: $body-background;
  border-radius: 6px;
  height: 6px;
  overflow: hidden;
  .progress {
    background-color: $black;
    height: 100%;
    border-radius: 6px;
  }
}
</style>
