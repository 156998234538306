<template>
  <card-item>
    <h1>{{ $t('company.upgrade_subscription_modal_title') }}</h1>
    <p>{{ $t('company.upgrade_subscription_modal_description') }}</p>

    <div class="spacer--30"></div>

    <div class="">
      <form @submit.prevent="changeSubscription">
        <label :key="deal.id" v-for="deal in availableDeals" class="grid-x auto plan" id="option_custom">
          <div class="cell small-10 plan__option">
            <h4>
              {{ deal.name }}
            </h4>
            <p>
              {{ deal.description }}
            </p>
          </div>
          <div class="cell auto plan__radio">
            <ab-radio
              v-model="selectedDeal"
              :option="deal.id"
              name="updateStorage"
              for="option_custom"
              class="radio__pos"
            />
          </div>
        </label>
        <div class="spacer--30"></div>
        <div class="text-right">
          <button
            type="submit"
            class="color--primary text-uppercase weight-600"
          >
            {{ $t('app.send') }}
          </button>
        </div>
      </form>
    </div>
  </card-item>
</template>

<script>
import backend from '@/backend'

export default {
  name: 'upgradeSubscriptionStorageModal',
  props: ['handlers'],
  data () {
    return {
      selectedDeal: null,
      availableDeals: []
    }
  },
  created () {
    backend
      .get(`${process.env.VUE_APP_URL}/subscription/deals`)
      .then(response => {
        this.availableDeals = response.data
      })
  },
  methods: {
    changeSubscription () {
      backend
        .post(`${process.env.VUE_APP_URL}/subscription/select_deal`, {
          deal: this.selectedDeal
        })
        .then(response => {
          this.$notify({
            group: 'custom-template',
            title: false,
            position: 'bottom left',
            text: this.$t('company.subscription_requested')
          })
          this.handlers.refresh()
          this.$emit('close')
        })
        .catch(() => {
          this.$modal.show('dialog', {
            title: this.$t('notify.unable_to_save_changes'),
            text: this.$t('company.could_not_subscribe'),
            buttons: [
              {
                title: this.$t('app.close'),
                default: true,
                handler: () => {
                  this.$modal.hide('dialog')
                }
              }
            ]
          })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.plan {
  margin-bottom: calc(10px);

  h4 {
    margin-bottom: calc(10px);

    i {
      padding-right: 10px;
    }
  }

  &__current {
    color: $dark-gray;
    background: $light-gray-bg;
    padding: calc(15px);
    border-radius: $global-radius 0 0 $global-radius;
  }

  &__option {
    background: $black;
    padding: calc(15px);
    color: $white;
    border-radius: $global-radius 0 0 $global-radius;
  }

  &__radio {
    padding: calc(28px) calc(15px) calc(15px) calc(15px);
    position: relative;
    background: $light-gray-bg;
    border-radius: 0 $global-radius $global-radius 0;
  }

  &:hover {
    box-shadow: rgba($black, 0.3) 0px 2px 5px;
    border-radius: $global-radius;
  }
}
</style>
